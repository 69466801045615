import { LoginCallback } from "@okta/okta-react";
import { Outlet } from "react-router-dom";

import { FeatureToggleKey } from "../hooks";
import { UserRoles } from "../lib/role-based-access-control";
import AccountDetails from "../pages/accounts/account-details/account-details";
import Accounts from "../pages/accounts/accounts";
import AccountsTable from "../pages/accounts/accounts-table/accounts-table";
import ExportRequestDetails from "../pages/export-request/export-request-details/export-request-details";
import ExportRequestList from "../pages/export-request/export-request-list/export-request-list";
import { ForwardTradeRequest } from "../pages/forward-trade-request";
import Home from "../pages/home/home";
import { ManagedCommodities } from "../pages/managed-commodities/managed-commodities";
import ManualPayments from "../pages/manual-payments/manual-payments";
import RetirementRequestDetails from "../pages/retirement-requests/retirement-request-details/retirement-request-details";
import RetirementRequestsList from "../pages/retirement-requests/retirement-requests-list/retirement-requests-list";
import TradingHalt from "../pages/trading-halt/trading-halt";
import TradingSummary from "../pages/trading-summary/trading-summary";
import { RegistryCode } from "../types/generated/graphql";
import { Route } from "./navigation.types";

export const routes: Route[] = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login/callback",
    element: <LoginCallback />,
  },
  {
    path: "/accounts",
    nav: {
      displayName: "Accounts",
      icon: "Users",
    },
    element: <Accounts />,
    secured: true,
    guards: {
      roles: [UserRoles.RoleSuperAdmin, UserRoles.RoleVerificationsAdmin],
    },
    children: [
      {
        path: "",
        element: <AccountsTable />,
      },
      {
        path: ":id",
        element: <AccountDetails />,
      },
      {
        path: `:id/managed-commodities`,
        element: <ManagedCommodities />,
      },
    ],
  },
  {
    path: "/forward-trade-request",
    nav: {
      displayName: "Forward Trade Requests",
      icon: "Request",
    },
    secured: true,
    element: <ForwardTradeRequest />,
    guards: {
      roles: [UserRoles.RoleSuperAdmin],
      requiredFeatureToggles: [FeatureToggleKey.EnableForwardTrade],
      allowedRegistries: [RegistryCode.Mrets],
    },
  },
  {
    path: "/retirement-requests",
    nav: {
      displayName: "Retirement Requests",
      icon: "Request",
    },
    secured: true,
    element: <Outlet />,
    children: [
      { path: "", element: <RetirementRequestsList /> },
      {
        path: ":id",
        element: <RetirementRequestDetails />,
      },
    ],
    guards: {
      roles: [UserRoles.RoleSuperAdmin],
    },
  },
  {
    nav: {
      displayName: "Trading Summary",
      icon: "Summary",
    },
    path: "/trading-summary",
    secured: true,
    element: <TradingSummary />,
    guards: {
      roles: [UserRoles.RoleSuperAdmin],
    },
  },
  {
    nav: {
      displayName: "Commodities List",
      icon: "HorizontalStack",
    },
    path: "/managed-commodities",
    secured: true,
    element: <ManagedCommodities />,
    guards: {
      roles: [UserRoles.RoleSuperAdmin],
    },
  },
  {
    path: "/export-requests",
    nav: {
      displayName: "Export Requests",
      icon: "Request",
    },
    secured: true,
    element: <Outlet />,
    children: [
      { path: "", element: <ExportRequestList /> },
      {
        path: ":id",
        element: <ExportRequestDetails />,
      },
    ],
    guards: {
      roles: [UserRoles.RoleSuperAdmin],
    },
  },
  {
    path: "/manual-payments",
    nav: {
      displayName: "Manual Payments",
      icon: "CreditCard",
    },
    secured: true,
    element: <ManualPayments />,
    guards: {
      roles: [UserRoles.RoleSuperAdmin],
    },
  },
  {
    path: "/trading-halt",
    nav: {
      displayName: "Trading Halt",
      icon: "Stop",
    },
    secured: true,
    element: <TradingHalt />,
    guards: {
      roles: [UserRoles.RoleSuperAdmin],
    },
  },
];
