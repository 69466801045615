import currency from "currency.js";
import { format } from "date-fns";
import { startCase } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications";
import { AppDateFormats } from "@/app/lib/format-date";
import { TradeContractData } from "@/app/pages/forward-trade-request/forward-trade-request.types";
import {
  MatchedTradeContractsDocument,
  MatchedTradeContractStatus,
  useUpdateMatchedTradeContractStatusMutation,
} from "@/app/types/generated/graphql";

import {
  ForwardTradeReviewSteps,
  stepToStatusMap,
  UseForwardTradeDetailsModalProps,
} from "./forward-trade-details-modal.types";
import { sanitizeEditorContent } from "./helpers";

export const modalTitle = {
  [ForwardTradeReviewSteps.REVIEW_DETAILS]: "Forward Trade Request Details",
  [ForwardTradeReviewSteps.REVIEW_ACCEPT]: "Are you sure you want to accept this forward trade request?",
  [ForwardTradeReviewSteps.REVIEW_REJECT]: "Are you sure you want to reject this forward trade request?",
};

export const useForwardTradeDetailsModal = ({
  closeModal,
  matchContractID,
  askTradeContractDetails,
  bidTradeContractDetails,
  buyerFee,
  sellerFee,
  matchContractStatus,
}: UseForwardTradeDetailsModalProps) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(ForwardTradeReviewSteps.REVIEW_DETAILS);
  const [rejectReason, setRejectReason] = useState("");

  const [updateMatchedTradeContractStatus, { loading }] = useUpdateMatchedTradeContractStatusMutation({
    refetchQueries: [MatchedTradeContractsDocument],
  });

  const getDefaultProductAttributeValue = useCallback(
    (values?: string[] | null) => {
      if (!values?.length) {
        return t("Any");
      }

      return values.join(", ");
    },
    [t],
  );

  const getProductAttributes = useCallback(
    (tradeContractDetails: TradeContractData) => {
      const { formattedAttributes, attributes } = tradeContractDetails;
      const { vintages, certificationsAndEligibilities, locations, fuelSources } = formattedAttributes;
      return [
        {
          label: "Vintage",
          value: getDefaultProductAttributeValue(vintages),
        },
        {
          label: "Eligibility",
          value: getDefaultProductAttributeValue(certificationsAndEligibilities.flatMap(({ value }) => value)),
        },
        {
          label: "Location",
          value: getDefaultProductAttributeValue(locations),
        },
        {
          label: "Fuel Type",
          value: getDefaultProductAttributeValue(fuelSources),
        },
        {
          label: "COD",
          value: attributes.commencementDate
            ? t(`Not older than {{cod}}`, {
                cod: format(new Date(attributes.commencementDate), AppDateFormats.AbbreviatedMonthFormat),
              })
            : t("Any"),
        },
      ];
    },
    [getDefaultProductAttributeValue, t],
  );

  const getTradeAttributes = useCallback((tradeContractDetails: TradeContractData, fee: number) => {
    const { createdDate, tradeSettlementDate, volume, unitPrice, account } = tradeContractDetails;

    return [
      {
        label: "Trade Lodgement Date",
        value: format(new Date(createdDate), AppDateFormats.AbbreviatedMonthFormat),
      },
      {
        label: "Trade Settlement Date",
        value: format(new Date(tradeSettlementDate), AppDateFormats.AbbreviatedMonthFormat),
      },
      {
        label: "Quantity",
        value: volume.toString(),
      },
      {
        label: "Price/Unit",
        value: currency(unitPrice, { fromCents: true }).format(),
      },
      {
        label: "Transaction Fee",
        value: currency(fee, { fromCents: true }).format(),
      },
      {
        label: "Business Name",
        value: account.company?.businessName ?? "-",
      },
      {
        label: "Email",
        value: account.email,
      },
    ];
  }, []);

  const askProductAttributes = useMemo(
    () => getProductAttributes(askTradeContractDetails),
    [getProductAttributes, askTradeContractDetails],
  );

  const bidProductAttributes = useMemo(
    () => getProductAttributes(bidTradeContractDetails),
    [getProductAttributes, bidTradeContractDetails],
  );

  const askTradeAttributes = useMemo(
    () => getTradeAttributes(askTradeContractDetails, sellerFee),
    [getTradeAttributes, askTradeContractDetails, sellerFee],
  );

  const bidTradeAttributes = useMemo(
    () => getTradeAttributes(bidTradeContractDetails, buyerFee),
    [getTradeAttributes, bidTradeContractDetails, buyerFee],
  );

  const handleReviewActions = useCallback(
    async (step: ForwardTradeReviewSteps) => {
      const status = stepToStatusMap[step as keyof typeof stepToStatusMap];
      try {
        await updateMatchedTradeContractStatus({
          variables: {
            input: {
              id: matchContractID,
              status,
              statusMessage: sanitizeEditorContent(rejectReason) || null,
            },
          },
        });
        notifications.success({
          description: t(`Forward Trade Request ${startCase(status.toLowerCase())}`),
        });
        closeModal();
      } catch (e) {
        e instanceof Error &&
          notifications.error({
            description: t(e.message),
          });
      }
    },
    [matchContractID, t, updateMatchedTradeContractStatus, closeModal, rejectReason],
  );

  const canPerformActions = useMemo(
    () => matchContractStatus === MatchedTradeContractStatus.Pending,
    [matchContractStatus],
  );

  return {
    askProductAttributes,
    bidProductAttributes,
    askTradeAttributes,
    bidTradeAttributes,
    currentStep,
    setCurrentStep,
    modalTitle: modalTitle[currentStep],
    handleReviewActions,
    loading,
    canPerformActions,
    rejectReason,
    setRejectReason,
  };
};
