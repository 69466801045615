import { Button, Editor, Flex, IconLoading, Modal, Text, ThemeUIStyleObject } from "@powerledger/ui-component-lib";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { isHtmlEmpty } from "../reject-retirement-request-modal/reject-retirement-request-modal";
import { ForwardTradeDetailsModalViewProps, ForwardTradeReviewSteps } from "./forward-trade-details-modal.types";

const DisplayForwardTradeAttribute = ({ label, value }: { label: string; value: string }) => {
  return (
    <Flex sx={{ flexDirection: "row", alignItems: "center", gap: 2 }}>
      <Text sx={{ minWidth: 180, maxWidth: 180, fontWeight: 500 }}>{label}</Text>
      <Text
        sx={{
          minWidth: 200,
          wordWrap: "break-word",
        }}
      >
        {value}
      </Text>
    </Flex>
  );
};

const DetailsWrapper = ({ children }: { children: ReactNode }) => (
  <Flex
    sx={{
      flexDirection: "column",
      gap: "14px",
      border: "1px solid var(--theme-ui-colors-shadow)",
      borderRadius: 8,
      padding: 3,
    }}
  >
    {children}
  </Flex>
);

const DetailsHeader = ({ text, sx }: { text: string; sx?: ThemeUIStyleObject }) => (
  <Text
    sx={{
      fontSize: "large",
      fontWeight: 600,
      fontFamily: "MintGroteskV08",
      ...sx,
    }}
  >
    {text}
  </Text>
);

const AttributesWrapper = ({ children }: { children: ReactNode }) => (
  <Flex
    sx={{
      justifyContent: "space-between",
    }}
  >
    {children}
  </Flex>
);

const AttributesDetails = ({ children }: { children: ReactNode }) => (
  <Flex
    sx={{
      flexDirection: "column",
      gap: 1,
      width: "50%",
    }}
  >
    {children}
  </Flex>
);

export const ForwardTradeDetailsModalView = ({
  visible,
  closeModal,
  askProductAttributes,
  bidProductAttributes,
  askTradeAttributes,
  bidTradeAttributes,
  currentStep,
  modalTitle,
  handleReviewActions,
  loading,
  canPerformActions,
  rejectReason,
  setRejectReason,
  setCurrentStep,
}: ForwardTradeDetailsModalViewProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      maskClosable={!loading}
      hideCloseButton={loading}
      sx={{
        minWidth: 800,
        maxHeight: 770,
        overflow: "auto",
        padding: 24,
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          gap: 3,
        }}
      >
        <DetailsHeader
          text={t(modalTitle)}
          sx={{
            fontSize: [3, null, 4],
            fontWeight: "bold",
          }}
        />
        {currentStep === ForwardTradeReviewSteps.REVIEW_DETAILS && (
          <>
            <DetailsWrapper>
              <DetailsHeader text={t("Ask Trade Contract Details")} />
              <AttributesWrapper>
                <AttributesDetails>
                  <DetailsHeader
                    text={t("Trade Attributes")}
                    sx={{
                      fontSize: "medium",
                      mb: 1,
                    }}
                  />
                  {askTradeAttributes.map((props) => (
                    <DisplayForwardTradeAttribute key={props.label} {...props} />
                  ))}
                </AttributesDetails>

                <AttributesDetails>
                  <DetailsHeader
                    text={t("RECs Attributes")}
                    sx={{
                      fontSize: "medium",
                      mb: 1,
                    }}
                  />
                  {askProductAttributes.map((props) => (
                    <DisplayForwardTradeAttribute key={props.label} {...props} />
                  ))}
                </AttributesDetails>
              </AttributesWrapper>
            </DetailsWrapper>
            <DetailsWrapper>
              <DetailsHeader text={t("Bid Trade Contract Details")} />
              <AttributesWrapper>
                <AttributesDetails>
                  <DetailsHeader
                    text={t("Trade Attributes")}
                    sx={{
                      fontSize: "medium",
                      mb: 1,
                    }}
                  />
                  {bidTradeAttributes.map((props) => (
                    <DisplayForwardTradeAttribute key={props.label} {...props} />
                  ))}
                </AttributesDetails>

                <AttributesDetails>
                  <DetailsHeader
                    text={t("RECs Attributes")}
                    sx={{
                      fontSize: "medium",
                      mb: 1,
                    }}
                  />
                  {bidProductAttributes.map((props) => (
                    <DisplayForwardTradeAttribute key={props.label} {...props} />
                  ))}
                </AttributesDetails>
              </AttributesWrapper>
            </DetailsWrapper>
            {canPerformActions && (
              <Flex
                sx={{
                  justifyContent: "flex-end",
                  gap: 2,
                }}
              >
                <Button variant="error" onClick={() => setCurrentStep(ForwardTradeReviewSteps.REVIEW_REJECT)}>
                  {t("Reject")}
                </Button>
                <Button variant="primary" onClick={() => setCurrentStep(ForwardTradeReviewSteps.REVIEW_ACCEPT)}>
                  {t("Accept")}
                </Button>
              </Flex>
            )}
          </>
        )}
        {currentStep === ForwardTradeReviewSteps.REVIEW_REJECT && (
          <Editor
            theme="snow"
            value={rejectReason}
            onChange={setRejectReason}
            placeholder="Reasons for rejection..."
            readOnly={loading}
          />
        )}
        {currentStep !== ForwardTradeReviewSteps.REVIEW_DETAILS && (
          <Flex
            sx={{
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button
              variant="text"
              disabled={loading}
              sx={{ color: "accentLight", textDecoration: "underline", textUnderlineOffset: 2 }}
              onClick={() => setCurrentStep(ForwardTradeReviewSteps.REVIEW_DETAILS)}
            >
              {t("Go back")}
            </Button>
            <Button
              variant={currentStep === ForwardTradeReviewSteps.REVIEW_ACCEPT ? "primary" : "error"}
              disabled={
                loading || (currentStep === ForwardTradeReviewSteps.REVIEW_REJECT && !isHtmlEmpty(rejectReason))
              }
              onClick={() => handleReviewActions(currentStep)}
            >
              {t("Confirm")}
            </Button>
            {loading && <IconLoading size="small" />}
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};
