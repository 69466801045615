import { Box, Button, Chip, Flex, Tooltip } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { TFunction } from "i18next";
import { startCase } from "lodash";
import ReactHtmlParser from "react-html-parser";
import { Column, Row } from "react-table";

import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { HeaderWithToolTip } from "@/app/components/tooltips/header-with-tooltip";
import { AppDateFormats, formatDate } from "@/app/lib/format-date";

import { MatchedTradeContractTableData, SeeDetailsProps } from "./forward-trade-request.types";
import { chipVariant, getTotalForwardTradeValue } from "./helpers";

export const getForwardTradeRequestColumns = (t: TFunction, handleSeeDetails: (args: SeeDetailsProps) => void) => {
  const columns: Column<MatchedTradeContractTableData>[] = [
    {
      Header: t("Trade Contract Number"),
      accessor: "matchedTradeContractNumber",
      disableSortBy: true,
    },
    {
      Header: <HeaderWithToolTip titleText="TMD" tooltipText="Trade Matched Date" />,
      accessor: "createdDate",
      disableSortBy: false,
      Cell: ({ value }: { value: string }) => (
        <TableValueWrapper
          value={formatDate(value, { formatStyle: AppDateFormats.AbbreviatedMonthFormat, noTZ: true })}
        />
      ),
    },
    {
      Header: <HeaderWithToolTip titleText="TSD" tooltipText="Trade Settlement Date" />,
      accessor: "tradeSettlementDate",
      disableSortBy: false,
      Cell: ({ value }: { value: string }) => (
        <TableValueWrapper
          value={formatDate(value, { formatStyle: AppDateFormats.AbbreviatedMonthFormat, noTZ: true })}
        />
      ),
    },
    {
      Header: t("Quantity"),
      accessor: "volume",
      disableSortBy: true,
    },
    {
      Header: (
        <Box
          sx={{
            textAlign: "right",
            width: 100,
          }}
        >
          {t("Unit Price")}
        </Box>
      ),
      disableSortBy: true,
      accessor: "unitPrice",
      Cell: ({ value }: { value: string }) => {
        return (
          <Box
            sx={{
              textAlign: "right",
              color: "textDark",
              width: 100,
            }}
          >
            {currency(value || 0, { separator: ",", fromCents: true }).format()}
          </Box>
        );
      },
    },
    {
      Header: () => (
        <Box
          sx={{
            textAlign: "right",
            width: 100,
          }}
        >
          {t("Total")}
        </Box>
      ),
      id: "totaltradevalue",
      disableSortBy: true,
      Cell: ({ row }: { row: Row<MatchedTradeContractTableData> }) => {
        const { original } = row;
        const tradeValue = getTotalForwardTradeValue(original.unitPrice, original.volume).value;
        return (
          <Box
            sx={{
              textAlign: "right",
              color: "textDark",
              width: 100,
            }}
          >
            {currency(tradeValue || 0, { separator: ",", fromCents: true }).format()}
          </Box>
        );
      },
    },
    {
      Header: t("Status"),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ value, row }: { value: string; row: Row<MatchedTradeContractTableData> }) => {
        const { statusMessage, id, status } = row.original;
        return (
          <Flex
            sx={{
              alignItems: "center",
            }}
          >
            <Chip
              sx={{
                variant: chipVariant[value as keyof typeof chipVariant],
              }}
              label={t(startCase(status))}
            />
            {statusMessage && (
              <Tooltip
                id={id}
                sx={{
                  display: "flex",
                  width: 16,
                  height: 16,
                  mb: 0,
                }}
                content={
                  <Box
                    sx={{
                      "& > p": {
                        marginY: 1,
                      },
                    }}
                  >
                    {ReactHtmlParser(t(statusMessage ?? ""))}
                  </Box>
                }
              />
            )}
          </Flex>
        );
      },
    },
    {
      Header: () => t("Actions"),
      id: "forwardActions",
      Cell: ({ row }: { row: Row<MatchedTradeContractTableData> }) => {
        const { id, askTradeContract, bidTradeContract, sellerFee, buyerFee, status } = row.original;
        return (
          <Button
            variant="pill.compactSecondary"
            sx={{ py: 1, mr: 2 }}
            onClick={() =>
              handleSeeDetails({
                matchContractID: id,
                askTradeContract,
                bidTradeContract,
                sellerFee,
                buyerFee,
                matchContractStatus: status,
              })
            }
          >
            {t("See Details")}
          </Button>
        );
      },
    },
  ];
  return columns;
};
