import { useCallback, useMemo } from "react";

import { useAppContext } from "@/app/context";
import { useAllFeatureToggleValues } from "@/app/hooks/use-feature-toggle/use-all-feature-toggle-values";
import { Registry, RegistryCode, useRegistriesQuery } from "@/app/types/generated/graphql";

import { UseRegistryDropdownFn } from "./registry-dropdown.types";

export const useRegistryDropdown: UseRegistryDropdownFn = () => {
  const {
    toggles: { enableErcotIntegration },
    loading: featureFlagsLoading,
  } = useAllFeatureToggleValues();

  const { userSelections, setUserSelections } = useAppContext();

  const { data, loading } = useRegistriesQuery({
    onCompleted: (data) => {
      if (!userSelections?.registry) {
        const registry = data?.registries?.find((reg) => reg.code === RegistryCode.Mrets) ?? data?.registries?.[0];
        setUserSelections({ ...userSelections, registry });
      }
    },
  });

  const registriesOptions = useMemo(
    () =>
      (data?.registries?.filter((registry) => {
        if (registry.code === RegistryCode.Icx) return false;

        if (!enableErcotIntegration && registry.code === RegistryCode.Ercot) return false;

        return true;
      }) ?? []) as Registry[],
    [data?.registries, enableErcotIntegration],
  );

  const activeRegistry = useMemo(
    () => registriesOptions?.find((registry) => registry?.id === userSelections?.registry?.id) ?? null,
    [registriesOptions, userSelections?.registry?.id],
  );

  const onActiveRegistryChange = useCallback(
    (registry: Registry | null) => {
      if (!registry) return;
      setUserSelections({
        ...userSelections,
        registry,
      });
    },
    [userSelections, setUserSelections],
  );

  return {
    loading: loading && featureFlagsLoading,
    registries: registriesOptions,
    activeRegistry,
    onActiveRegistryChange,
  };
};
