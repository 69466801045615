import { Box, Flex, ThemeToggle } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { APOLLO_GRAPH_VARIANT } from "@/app/lib/env-helpers";

import { RegistryDropdown } from "../../registry-dropdown";
import { LayoutHeaderProps } from "./layout-header.types";

export const LayoutHeader: React.FC<LayoutHeaderProps> = ({ header, enableMultipleRegistry }) => {
  const { t } = useTranslation();
  return (
    header ?? (
      <Flex
        sx={{
          alignItems: "center",
          height: "100%",
          gap: 3,
          mx: 3,
          mr: 4,
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        {enableMultipleRegistry && (
          <Box sx={{ width: "130px" }}>
            <RegistryDropdown />
          </Box>
        )}
        {APOLLO_GRAPH_VARIANT === "development" && (
          <ThemeToggle checkedLabel={t("Dark Mode")} uncheckedLabel={t("Light Mode")} />
        )}
      </Flex>
    )
  );
};
