import { find, flatten, map } from "lodash";

import {
  OrderPosition,
  RecAssetAttributes,
  RecOrderAttributes,
  TradeAttributes,
  TradePosition,
  VintageHalf,
} from "../types/generated/graphql";
import { FormattedOption, RECOrderAttributeOptions } from "./format-rec-options";
import { getVintageHalfText } from "./get-vintage-half-text";
import i18n from "./i18n";

export type CertificationAndEliglibilityType = {
  label: string;
  value: string[];
};

export const CRS = "CRS";
export const CERTIFICATIONS = "certifications";
export const ELIGIBILITIES = "eligibilities";
export type ReturnTypeFormatAttributes = {
  certifications: string[];
  eligibilities: string[];
  fuelSources: string[];
  location: string;
  project: string;
  vintage: string;
  certificationsAndEligibilities: CertificationAndEliglibilityType[];
  //TradeAttributes type does not include vintage,location and vintageHalf properties;
  // so adding new attributes as in traceX web app
  vintages: string[];
  locations: string[];
  projects: string[];
};

/** RecOrderAttributes & TradeAttributes have multiple values(vintages, locations) but AssetAttributes dont */
const isOrderAttributes = (obj: any): obj is RecOrderAttributes | TradeAttributes =>
  Object.hasOwn(obj, "locations") || Object.hasOwn(obj, "projects") || Object.hasOwn(obj, "vintages");

export const noAssetAttributesFoundErrorMessage = "No asset attributes found!";

const HALF_REGEX = new RegExp(`${VintageHalf.FrontHalf}|${VintageHalf.BackHalf}`, "g");
const getTextToShowOnValue = (vintages: string[]) => {
  return vintages.map((vin) => `${vin.split("-")[0]} - ${i18n.t(vin.match(HALF_REGEX)?.[0] ?? "")}`);
};

export const formatAttributes = ({
  attributes,
  filteredEligibility,
  position,
  options,
}: {
  attributes: RecOrderAttributes | RecAssetAttributes | TradeAttributes;
  filteredEligibility?: string;
  position?: OrderPosition | TradePosition;
  options?: RECOrderAttributeOptions;
}): ReturnTypeFormatAttributes => {
  if (!options) throw new Error(i18n.t(noAssetAttributesFoundErrorMessage));
  const certifications: string[] =
    map(attributes.certifications, (e) => find(options.certificationOptions, { value: e })?.label || "") || [];
  const defaultValue = position === OrderPosition.Bid ? "Any" : "-";
  const eligibilities: string[] =
    map(
      attributes.eligibilities?.slice().sort((x?: string) => (x === filteredEligibility ? -1 : 1)),
      (e) => find(options.eligibilityOptions, { value: e })?.label || "",
    ) || [];

  const [location, locations] = (() => {
    const locs: string[] = [];
    const loc: string[] = [];
    const innerOptions: FormattedOption[] = [];
    // Push the nested options outside (Only One level of nesting can occur for now)
    for (const option of options.locationOptions) {
      if (option.options) innerOptions.push(option.options);
    }

    //Flatten the option
    const flattenOption = flatten(innerOptions);

    if (isOrderAttributes(attributes)) {
      // Create the object for Values for O(1) retrieval
      const values: Record<string, boolean> = (attributes.locations || [])?.reduce(
        (acc, val) => ({ ...acc, [val]: true }),
        {},
      );
      for (const option of flattenOption) {
        //Check if pption is present in our Retrieval Object
        const opt = values[option.value];
        if (opt) {
          locs.push(option.label);
        }
      }
    } else {
      for (const option of flattenOption) {
        //Check if option value equals to provided location
        const opt = option.value === attributes.location;

        if (opt) {
          loc.push(option.label);
        }
      }
    }
    return [loc, locs];
  })();

  const projects = isOrderAttributes(attributes)
    ? options.projectOptions?.filter((p) => attributes.projects?.includes(p.value))?.map((l) => l?.label)
    : [];

  const vintage = (() => {
    const yearValue =
      "vintage" in attributes
        ? options.vintageOptions.find((v) => v.value === attributes.vintage?.toString())?.label ?? ""
        : "";
    return (
      yearValue + getVintageHalfText("vintageHalf" in attributes ? attributes.vintageHalf : null, yearValue ? "-" : "")
    );
  })();

  const vintages = (() => {
    const values = isOrderAttributes(attributes) ? attributes.vintages ?? [] : [];
    return getTextToShowOnValue(values);
  })();

  const fuelSources: string[] =
    map(
      attributes.fuelSources?.slice().sort((x?: string) => (x === filteredEligibility ? -1 : 1)),
      (e) => find(options.fuelSourceOptions, { value: e })?.label ?? "",
    ) || [];

  return {
    vintage,
    vintages,
    eligibilities,
    location: location?.[0] || defaultValue,
    locations: locations?.length ? locations : [defaultValue],
    fuelSources: fuelSources?.length ? fuelSources : [],
    project:
      find(options.projectOptions, (p) => "project" in attributes && p.value === attributes.project)?.label ||
      defaultValue,
    projects: projects?.length ? projects : [defaultValue],
    certifications,
    certificationsAndEligibilities: [
      {
        label: CERTIFICATIONS,
        value: certifications,
      },
      {
        label: ELIGIBILITIES,
        value: eligibilities,
      },
    ],
  };
};
