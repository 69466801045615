import { Dispatch, SetStateAction } from "react";

import { TradeContractData } from "@/app/pages/forward-trade-request/forward-trade-request.types";
import { MatchedTradeContractStatus } from "@/app/types/generated/graphql";

export type ForwardTradeDetailsDefaultProps = {
  visible: boolean;
  closeModal: VoidFunction;
};

export type DisplayForwardTradeAttributeProps = {
  label: string;
  value: string;
};

export type ForwardTradeDetailsCustomProps = {
  matchContractID: string;
  askTradeContractDetails: TradeContractData;
  bidTradeContractDetails: TradeContractData;
  sellerFee: number;
  buyerFee: number;
  matchContractStatus: MatchedTradeContractStatus;
};

export type ForwardTradeDetailsProps = ForwardTradeDetailsDefaultProps & ForwardTradeDetailsCustomProps;

export type ForwardTradeDetailsModalViewProps = ForwardTradeDetailsDefaultProps & {
  askProductAttributes: DisplayForwardTradeAttributeProps[];
  bidProductAttributes: DisplayForwardTradeAttributeProps[];
  askTradeAttributes: DisplayForwardTradeAttributeProps[];
  bidTradeAttributes: DisplayForwardTradeAttributeProps[];
  currentStep: ForwardTradeReviewSteps;
  setCurrentStep: Dispatch<SetStateAction<ForwardTradeReviewSteps>>;
  modalTitle: string;
  handleReviewActions: (step: ForwardTradeReviewSteps) => void;
  loading: boolean;
  canPerformActions: boolean;
  rejectReason: string;
  setRejectReason: Dispatch<SetStateAction<string>>;
};

export type UseForwardTradeDetailsModalProps = {
  closeModal: VoidFunction;
} & ForwardTradeDetailsCustomProps;

export enum ForwardTradeReviewSteps {
  REVIEW_DETAILS,
  REVIEW_ACCEPT,
  REVIEW_REJECT,
}

export const stepToStatusMap = {
  [ForwardTradeReviewSteps.REVIEW_ACCEPT]: MatchedTradeContractStatus.Accepted,
  [ForwardTradeReviewSteps.REVIEW_REJECT]: MatchedTradeContractStatus.Rejected,
} as const;
