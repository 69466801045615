import { Holding, VintageHalf } from "@/app/types/generated/graphql";
export const demoHoldings: Array<Holding> = [
  {
    id: "79ace926-9f90-4037-be80-baca08455bf8",
    availableBalance: 600,
    currentBalance: 600,
    project: "Nobles-Wind",
    attributes: {
      __typename: "RecAssetAttributes",
      vintage: 2019,
      vintageHalf: VintageHalf["FrontHalf"],
      eligibilities: ["ecologo"],
      location: "TX",
      fuelSources: ["WND"],
      project: "Nobles-Wind",
      certifications: [],
    },
    __typename: "Holding",
  },
  {
    id: "79ace926-9f90-4037-be80-baca08455b48",
    availableBalance: 800,
    currentBalance: 800,
    project: "Viking-Wind",
    attributes: {
      __typename: "RecAssetAttributes",
      vintage: 2020,
      vintageHalf: VintageHalf["FrontHalf"],
      eligibilities: ["mn_ses_small_solar"],
      location: "MN",
      fuelSources: ["SO1"],
      project: "Viking-Wind",
      certifications: ["CRS"],
    },
    __typename: "Holding",
  },
  {
    id: "79ace926-9f90-4037-be8e-baca08455bf8",
    availableBalance: 20000,
    currentBalance: 35000,
    project: "Chipmunk-Solar",
    attributes: {
      __typename: "RecAssetAttributes",
      vintage: 2021,
      vintageHalf: VintageHalf["BackHalf"],

      eligibilities: ["il_utility"],
      location: "IL",
      fuelSources: ["SO1"],
      project: "Chipmunk-Solar",
      certifications: ["CRS"],
    },
    __typename: "Holding",
  },
  {
    id: "79ace926-9f9c-4037-be80-baca08455bf8",
    availableBalance: 15000,
    currentBalance: 15000,
    project: "Chipmunk-Solar",
    attributes: {
      __typename: "RecAssetAttributes",
      vintage: 2021,
      vintageHalf: VintageHalf["BackHalf"],
      eligibilities: ["wi_bankable", "mt"],
      location: "TX",
      fuelSources: ["SO1"],
      project: "Chipmunk-Solar",
      certifications: ["TUV Green Label"],
    },
    __typename: "Holding",
  },
  {
    id: "72ace926-9f90-4037-be80-baca08455bf8",
    availableBalance: 8000,
    currentBalance: 8000,
    project: "Holiday-Creek-Solar",
    attributes: {
      __typename: "RecAssetAttributes",
      vintage: 2020,
      vintageHalf: VintageHalf["FrontHalf"],
      eligibilities: ["oh", "sd"],
      location: "OH",
      fuelSources: ["WND"],
      project: "Holiday-Creek-Solar",
      certifications: ["CRS", "TUV Green Label"],
    },
    __typename: "Holding",
  },
];
