import { RegistryCode } from "@/app/types/generated/graphql";

export const demoRegistries = [
  {
    id: "e2e2ec2d-5158-4c7b-9996-b70e36c7dec2",
    code: RegistryCode.Mrets,
    shortName: "M-RETS",
    fullName: "Midwest Renewable Energy Tracking System",
  },
  {
    id: "2ea55dfd-8f8d-4312-984d-f50ff829f63c",
    code: RegistryCode.Ercot,
    shortName: "ERCOT",
    fullName: "Electric Reliability Council of Texas",
  },
];
