import { FeatureToggleKey } from "../hooks";
import { RegistryCode } from "../types/generated/graphql";

export const hasRequiredFeatureTogglesEnabled = (
  toggles: Partial<Record<FeatureToggleKey, any>>,
  requiredFeatureToggles?: FeatureToggleKey[],
) => {
  if (!requiredFeatureToggles) {
    return true;
  }

  return requiredFeatureToggles.every((feature) => !!toggles[feature]);
};

export const hasRequiredRegistries = (activeRegistry?: RegistryCode, allowedRegistries?: RegistryCode[]) => {
  if (!activeRegistry) return false;
  if (!allowedRegistries?.length) return true;

  return allowedRegistries.includes(activeRegistry);
};
