import { useOktaAuth } from "@okta/okta-react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Box } from "theme-ui";

import { LoadingOverlayIndicator } from "../components";
import { Layout } from "../components/layout";
import { useAppContext } from "../context";
import { useAllFeatureToggleValues } from "../hooks/use-feature-toggle/use-all-feature-toggle-values";
import { useOktaRoles } from "../hooks/use-okta-roles";
import { hasRequiredFeatureTogglesEnabled, hasRequiredRegistries } from "../lib/access-control";
import { grantAccess } from "../lib/role-based-access-control";
import { RegistryCode, useRegistriesQuery } from "../types/generated/graphql";
import { Guards } from "./navigation.types";
import { routes } from "./routes";

const PrivateRoute = ({ children, guards }: { guards?: Guards; children: JSX.Element }) => {
  const { authState } = useOktaAuth();
  const { userRoles } = useOktaRoles();
  const { userSelections } = useAppContext();

  const { toggles, loading: featureTogglesLoading } = useAllFeatureToggleValues();

  if (!authState || !userRoles || featureTogglesLoading) {
    return <LoadingOverlayIndicator />;
  }

  if (!hasRequiredFeatureTogglesEnabled(toggles, guards?.requiredFeatureToggles)) {
    return <Navigate to="/" replace />;
  }

  if (!grantAccess(guards?.roles || [], userRoles)) {
    return <Navigate to="/" replace />;
  }

  if (!authState?.isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (!hasRequiredRegistries(userSelections?.registry?.code as RegistryCode, guards?.allowedRegistries)) {
    return <Navigate to="/" replace />;
  }

  return (
    <Layout userRoles={userRoles} toggles={toggles}>
      <Box as="main" sx={{ m: 4 }}>
        {children}
      </Box>
    </Layout>
  );
};

export const AppRouter = () => {
  const { authState } = useOktaAuth();
  const { userSelections, setUserSelections } = useAppContext();

  const { loading, error } = useRegistriesQuery({
    skip: Boolean(userSelections?.registry) || !authState?.isAuthenticated,
    onCompleted: (data) => {
      if (!userSelections?.registry) {
        const registry = data?.registries?.find((reg) => reg.code === RegistryCode.Mrets) ?? data?.registries?.[0];
        setUserSelections({ ...userSelections, registry });
      }
    },
  });

  if (loading) {
    return <LoadingOverlayIndicator />;
  }

  if (error) {
    throw new Error("Failed to fetch registries");
  }

  return (
    <Routes>
      {/* 
          One level children only considered, 
        */}
      {routes.map(({ path, element, children, secured, guards }) => (
        <Route
          key={path}
          path={path}
          element={secured ? <PrivateRoute guards={guards}>{element}</PrivateRoute> : element}
        >
          {children?.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Route>
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
