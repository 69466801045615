import OktaAuth from "@okta/okta-auth-js";
import { ConfigCatProvider, createFlagOverridesFromMap, OverrideBehaviour, PollingMode } from "configcat-react";
import { BrowserRouter } from "react-router-dom";

import { tokenStorageKey } from "./container/apollo-client";
import { CONFIGCAT_CACHE_TTL_DEFAULT } from "./hooks";
import {
  CONFIGCAT_CACHE_TTL,
  CONFIGCAT_OVERRIDES,
  CONFIGCAT_SDK_KEY,
  OKTA_CLIENT_ID,
  OKTA_ISSUER,
} from "./lib/env-helpers";
import { AppRouter } from "./navigation/app-router";
import { OktaSecurity } from "./navigation/okta-security";

const oktaAuth = new OktaAuth({
  issuer: OKTA_ISSUER,
  clientId: OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  pkce: true,
  tokenManager: {
    storageKey: tokenStorageKey,
  },
});

export default function App() {
  return (
    <ConfigCatProvider
      sdkKey={CONFIGCAT_SDK_KEY}
      pollingMode={PollingMode.LazyLoad}
      options={{
        flagOverrides: createFlagOverridesFromMap(CONFIGCAT_OVERRIDES, OverrideBehaviour.LocalOverRemote),
        cacheTimeToLiveSeconds: Number(CONFIGCAT_CACHE_TTL) || CONFIGCAT_CACHE_TTL_DEFAULT,
      }}
    >
      <BrowserRouter>
        <OktaSecurity oktaAuth={oktaAuth}>
          <AppRouter />
        </OktaSecurity>
      </BrowserRouter>
    </ConfigCatProvider>
  );
}
