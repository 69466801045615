import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ModalContext } from "@/app/components/modal-provider/modal-provider";
import { useRecOptions } from "@/app/hooks/use-rec-options";
import { formatAttributes, ReturnTypeFormatAttributes } from "@/app/lib/format-attributes";
import {
  MatchedTradeContract,
  SortOrderInput,
  TradePosition,
  useMatchedTradeContractsQuery,
} from "@/app/types/generated/graphql";

import {
  ForwardTradeRequestFilter,
  SeeDetailsProps,
  TradeContractDetails,
  UseForwardTradeRequestFn,
} from "./forward-trade-request.types";
import { getForwardTradeRequestColumns } from "./forward-trade-request-column";

export const useForwardTradeRequest: UseForwardTradeRequestFn = () => {
  const { t } = useTranslation();
  const [filterValues, setFilterValues] = useState<ForwardTradeRequestFilter>({});

  const { pageInfo, fetchData, resetPage, setTotalItems, offset, sort } = useTablePaginationManager<
    MatchedTradeContract,
    SortOrderInput[]
  >({});

  const { recOptions, loading: recOptionsLoading } = useRecOptions();

  const { showForwardTradeDetailsModal } = useContext(ModalContext);

  const {
    data: MatchedTradeContractsData,
    loading,
    previousData,
    refetch,
  } = useMatchedTradeContractsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        paginationInput: {
          offset,
          limit: pageInfo.pageSize,
        },
        sortOrderInputs: sort,
        where: {
          numberIn: filterValues.matchedContractNumber ? [filterValues.matchedContractNumber] : null,
          status: filterValues.status ?? null,
        },
      },
    },
    onCompleted(data) {
      setTotalItems(data.matchedTradeContracts.offsetInfo.total ?? 0);
    },
  });

  const handleFilterChange = useCallback(
    (key, value) => {
      setFilterValues((prev) => ({ ...prev, [key]: value }));
      resetPage();
    },
    [resetPage],
  );

  const reloadMatchedTradeContracts = useCallback(() => {
    refetch();
  }, [refetch]);

  const tableData = useMemo(
    () => (MatchedTradeContractsData ?? previousData)?.matchedTradeContracts.matchedTradeContracts ?? [],
    [MatchedTradeContractsData, previousData],
  );

  const formatTradeContractDetails = useCallback(
    ({ attributes, ...rest }: TradeContractDetails) => {
      const formattedAttributes = recOptions
        ? formatAttributes({
            attributes,
            position: TradePosition.Bid, // Always display "ANY" regardless of the trade type.
            options: recOptions,
          })
        : ({} as ReturnTypeFormatAttributes);

      return {
        formattedAttributes,
        attributes,
        ...rest,
      };
    },
    [recOptions],
  );

  const handleSeeDetails = useCallback(
    ({
      matchContractID,
      askTradeContract,
      bidTradeContract,
      sellerFee,
      buyerFee,
      matchContractStatus,
    }: SeeDetailsProps) => {
      showForwardTradeDetailsModal({
        matchContractID,
        askTradeContractDetails: formatTradeContractDetails(askTradeContract),
        bidTradeContractDetails: formatTradeContractDetails(bidTradeContract),
        sellerFee,
        buyerFee,
        matchContractStatus,
      });
    },
    [showForwardTradeDetailsModal, formatTradeContractDetails],
  );

  const tableColumns = useMemo(() => getForwardTradeRequestColumns(t, handleSeeDetails), [t, handleSeeDetails]);

  return {
    filterValues,
    handleFilterChange,
    tableColumns,
    tableData,
    reloadMatchedTradeContracts,
    pageInfo,
    fetchData,
    loading: loading || recOptionsLoading,
  };
};
