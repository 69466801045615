import { Flex, LegacySelect, SearchSVG, Table } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { Input } from "@/app/components";
import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { getSelectTranslation, getTableTranslation } from "@/app/lib/get-translations-for-components";

import { ForwardTradeRequestViewProps } from "./forward-trade-request.types";
import { forwardTradeStatusOptions } from "./helpers";

export const ForwardTradeRequestView = ({
  filterValues,
  handleFilterChange,
  tableColumns,
  tableData,
  reloadMatchedTradeContracts,
  pageInfo,
  fetchData,
  loading,
}: ForwardTradeRequestViewProps) => {
  const { t } = useTranslation();
  return (
    <Flex
      sx={{
        flexDirection: "column",
        gap: 4,
      }}
    >
      <Flex
        sx={{
          flexGrow: 1,
          flexWrap: "wrap",
          gap: 2,
          justifyContent: "flex-end",
        }}
      >
        <Input
          debounce
          name="matchedContractNumber"
          placeholder="Enter Match Contract Number"
          defaultValue={filterValues.matchedContractNumber}
          onChange={(e) => handleFilterChange("matchedContractNumber", e.target.value)}
          prefix={{
            outside: false,
            component: (
              <Flex
                sx={{
                  svg: {
                    path: {
                      fill: "text",
                    },
                  },
                }}
              >
                <SearchSVG width={20} />
              </Flex>
            ),
          }}
          sx={{
            minWidth: 250,
          }}
        />
        <LegacySelect
          translation={getSelectTranslation(t)}
          placeholder="Select Trade Status"
          isClearable
          onChange={(option) => handleFilterChange("status", option?.value ?? null)}
          options={forwardTradeStatusOptions}
          sx={{
            minWidth: 220,
          }}
          value={forwardTradeStatusOptions.find((option) => option.value === filterValues.status)}
        />
        <RefreshIconButton loading={loading} fetchData={reloadMatchedTradeContracts} />
      </Flex>
      <Table
        columns={tableColumns}
        dataSource={tableData}
        translation={getTableTranslation(t)}
        manualPagination
        pageInfo={pageInfo}
        fetchData={fetchData}
        loading={loading}
        showScrollArrows
      />
    </Flex>
  );
};
