import { createContext, lazy, Suspense, useCallback, useMemo, useState } from "react";

import AccountRequestEditEntityModal from "../account-request-edit-entity-modal/account-request-edit-entity-modal";
import { AccountRequestEditEntityModalCustomProps } from "../account-request-edit-entity-modal/account-request-edit-entity-modal.types";
import { ConfirmAcceptRejectResubmitApplicationModalCustomProps } from "../confirm-accept-reject-resubmit-application-modal";
import { ConfirmCommodityExportCustomProps } from "../confirm-commodity-export-modal/confirm-commodity-export-modal.types";
import ConfirmDeleteTradingHaltModal from "../confirm-delete-trading-halt-modal/confirm-delete-trading-halt-modal";
import { ConfirmDeleteTradingHaltModalCustomProps } from "../confirm-delete-trading-halt-modal/confirm-delete-trading-halt-modal.types";
import ConfirmationModal from "../confirmation-modal/confirmation-modal";
import { ConfirmationModalCustomProps } from "../confirmation-modal/confirmation-modal.types";
import EditTradingHaltModal from "../edit-trading-halt-modal.tsx/edit-trading-halt-modal";
import { EditTradingHaltModalCustomProps } from "../edit-trading-halt-modal.tsx/edit-trading-halt-modal.types";
import { ForwardTradeDetailsModal } from "../forward-trade-details-modal/forward-trade-details-modal";
import { ForwardTradeDetailsCustomProps } from "../forward-trade-details-modal/forward-trade-details-modal.types";
import { IdentityMetadataModalCustomProps } from "../identity-metadata-modal/identity-metadata-modal.types";
import { LoadingOverlayIndicator } from "../loading-overlay-indicator";
import ManagedCommoditiesExportModal from "../managed-commodities-export-modal/managed-commodities-export-modal";
import { ManagedCommoditiesExportModalCustomProps } from "../managed-commodities-export-modal/managed-commodities-export-modal.types";
import RejectRetirementRequestModal from "../reject-retirement-request-modal/reject-retirement-request-modal";
import { RejectRetirementRequestModalCustomProps } from "../reject-retirement-request-modal/reject-retirement-request-modal.types";
import { ModalEnums, ModalHandler, ModalProviderProps, ModalState } from "./modal-provider.types";

const ContactSupportModal = lazy(() => import("../contact-support-modal/contact-support-modal"));
const ConfirmAcceptRejectResubmitApplicationModal = lazy(
  () => import("../confirm-accept-reject-resubmit-application-modal/confirm-accept-reject-resubmit-application-modal"),
);
const IdentityMetaDataModal = lazy(() => import("../identity-metadata-modal/identity-metadata-modal"));
const ConfirmCommodityExportModal = lazy(
  () => import("../confirm-commodity-export-modal/confirm-commodity-export-modal"),
);

export const ModalContext = createContext({} as ModalHandler);

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modalState, setModalState] = useState<ModalState | null>(null);

  const renderModals = useMemo(() => {
    switch (modalState?.type) {
      case ModalEnums.RejectRetirementRequestModal:
        return (
          <RejectRetirementRequestModal
            visible={modalState?.type === ModalEnums.RejectRetirementRequestModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );
      case ModalEnums.ConfirmationModal:
        return (
          <ConfirmationModal
            visible={modalState?.type === ModalEnums.ConfirmationModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );
      case ModalEnums.ConfirmCommodityExport:
        return (
          <ConfirmCommodityExportModal
            visible={modalState?.type === ModalEnums.ConfirmCommodityExport}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );
      case ModalEnums.ContactSupportModal:
        return (
          <ContactSupportModal
            visible={modalState?.type === ModalEnums.ContactSupportModal}
            closeModal={() => setModalState(null)}
          />
        );
      case ModalEnums.ConfirmAcceptRejectResubmitApplicationModal:
        return (
          <ConfirmAcceptRejectResubmitApplicationModal
            visible={modalState?.type === ModalEnums.ConfirmAcceptRejectResubmitApplicationModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );

      case ModalEnums.IdentityMetaDataModal:
        return (
          <IdentityMetaDataModal
            visible={modalState?.type === ModalEnums.IdentityMetaDataModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );
      case ModalEnums.EditTradingHaltModal:
        return (
          <EditTradingHaltModal
            visible={modalState?.type === ModalEnums.EditTradingHaltModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );
      case ModalEnums.ConfirmDeleteTradingHaltModal:
        return (
          <ConfirmDeleteTradingHaltModal
            visible={modalState?.type === ModalEnums.ConfirmDeleteTradingHaltModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );
      case ModalEnums.AccountRequestEditEntityModal:
        return (
          <AccountRequestEditEntityModal
            visible={modalState?.type === ModalEnums.AccountRequestEditEntityModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );
      case ModalEnums.ManagedCommoditiesExportModal:
        return (
          <ManagedCommoditiesExportModal
            visible={modalState?.type === ModalEnums.ManagedCommoditiesExportModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );
      case ModalEnums.ForwardTradeDetailsModal:
        return (
          <ForwardTradeDetailsModal
            visible={modalState?.type === ModalEnums.ForwardTradeDetailsModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );

      default:
        return null;
    }
  }, [modalState]);

  const showConfirmationModal = useCallback((customProps: ConfirmationModalCustomProps) => {
    setModalState({
      type: ModalEnums.ConfirmationModal,
      customProps,
    });
  }, []);

  const showContactSupportModal = useCallback(() => {
    setModalState({
      type: ModalEnums.ContactSupportModal,
      customProps: undefined,
    });
  }, []);

  const showConfirmAcceptRejectResubmitApplicationModal = useCallback(
    (customProps: ConfirmAcceptRejectResubmitApplicationModalCustomProps) => {
      setModalState({
        type: ModalEnums.ConfirmAcceptRejectResubmitApplicationModal,
        customProps,
      });
    },
    [],
  );

  const showIdentityMetaDataModal = useCallback((customProps: IdentityMetadataModalCustomProps) => {
    setModalState({
      type: ModalEnums.IdentityMetaDataModal,
      customProps,
    });
  }, []);

  const showEditTradingHaltModal = useCallback((customProps: EditTradingHaltModalCustomProps) => {
    setModalState({
      type: ModalEnums.EditTradingHaltModal,
      customProps,
    });
  }, []);

  const showConfirmDeleteTradingHaltModal = useCallback((customProps: ConfirmDeleteTradingHaltModalCustomProps) => {
    setModalState({
      type: ModalEnums.ConfirmDeleteTradingHaltModal,
      customProps,
    });
  }, []);

  const showConfirmCommodityExportModal = useCallback((customProps: ConfirmCommodityExportCustomProps) => {
    setModalState({
      type: ModalEnums.ConfirmCommodityExport,
      customProps,
    });
  }, []);

  const showAccountRequestEditEntityModal = useCallback((customProps: AccountRequestEditEntityModalCustomProps) => {
    setModalState({
      type: ModalEnums.AccountRequestEditEntityModal,
      customProps,
    });
  }, []);

  const showRejectRetirementRequestModal = useCallback((customProps: RejectRetirementRequestModalCustomProps) => {
    setModalState({
      type: ModalEnums.RejectRetirementRequestModal,
      customProps,
    });
  }, []);

  const showManagedCommoditiesExportModal = useCallback((customProps: ManagedCommoditiesExportModalCustomProps) => {
    setModalState({
      type: ModalEnums.ManagedCommoditiesExportModal,
      customProps,
    });
  }, []);

  const showForwardTradeDetailsModal = useCallback((customProps: ForwardTradeDetailsCustomProps) => {
    setModalState({
      type: ModalEnums.ForwardTradeDetailsModal,
      customProps,
    });
  }, []);

  return (
    <ModalContext.Provider
      value={useMemo(
        () => ({
          showContactSupportModal,
          showConfirmAcceptRejectResubmitApplicationModal,
          showIdentityMetaDataModal,
          showEditTradingHaltModal,
          showConfirmDeleteTradingHaltModal,
          showConfirmCommodityExportModal,
          showAccountRequestEditEntityModal,
          showConfirmationModal,
          showRejectRetirementRequestModal,
          showManagedCommoditiesExportModal,
          showForwardTradeDetailsModal,
        }),
        [
          showContactSupportModal,
          showConfirmDeleteTradingHaltModal,
          showConfirmCommodityExportModal,
          showEditTradingHaltModal,
          showIdentityMetaDataModal,
          showConfirmAcceptRejectResubmitApplicationModal,
          showAccountRequestEditEntityModal,
          showConfirmationModal,
          showRejectRetirementRequestModal,
          showManagedCommoditiesExportModal,
          showForwardTradeDetailsModal,
        ],
      )}
    >
      <Suspense fallback={<LoadingOverlayIndicator />}>{renderModals}</Suspense>
      {children}
    </ModalContext.Provider>
  );
};
