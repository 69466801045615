import "./wdyr";
import "./app/lib/custom-yup-methods";
import "./fonts.css";
import "react-toastify/dist/ReactToastify.css";

import { ApolloProvider } from "@apollo/client";
import { PLCLProvider } from "@powerledger/ui-component-lib";
import React, { Suspense } from "react";
import ReactDom from "react-dom";
import { ToastContainer } from "react-toastify";

import App from "./app/app";
import { LoadingOverlayIndicator } from "./app/components";
import { GlobalErrorBoundary } from "./app/components/error-boundary";
import { ModalProvider } from "./app/components/modal-provider/modal-provider";
import container from "./app/container";
import { themeSwitchOverride, tracexTheme } from "./app/container/theme-override";
import { AppContextProvider } from "./app/context";
import { APOLLO_GRAPH_VARIANT } from "./app/lib/env-helpers";

const themeSwitch = APOLLO_GRAPH_VARIANT === "development" ? themeSwitchOverride : {};

ReactDom.render(
  <React.StrictMode>
    <PLCLProvider themeOverwrites={{ ...tracexTheme, ...themeSwitch }}>
      <GlobalErrorBoundary>
        <AppContextProvider>
          <ApolloProvider client={container.apolloClient}>
            <Suspense fallback={<LoadingOverlayIndicator />}>
              <ModalProvider>
                <ToastContainer />
                <App />
              </ModalProvider>
            </Suspense>
          </ApolloProvider>
        </AppContextProvider>
      </GlobalErrorBoundary>
    </PLCLProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
