import { AccountRequestEditEntityModalCustomProps } from "../account-request-edit-entity-modal/account-request-edit-entity-modal.types";
import { ConfirmAcceptRejectResubmitApplicationModalCustomProps } from "../confirm-accept-reject-resubmit-application-modal";
import { ConfirmCommodityExportCustomProps } from "../confirm-commodity-export-modal/confirm-commodity-export-modal.types";
import { ConfirmDeleteTradingHaltModalCustomProps } from "../confirm-delete-trading-halt-modal/confirm-delete-trading-halt-modal.types";
import { ConfirmationModalCustomProps } from "../confirmation-modal/confirmation-modal.types";
import { EditTradingHaltModalCustomProps } from "../edit-trading-halt-modal.tsx/edit-trading-halt-modal.types";
import { ForwardTradeDetailsCustomProps } from "../forward-trade-details-modal/forward-trade-details-modal.types";
import { IdentityMetadataModalCustomProps } from "../identity-metadata-modal/identity-metadata-modal.types";
import { ManagedCommoditiesExportModalCustomProps } from "../managed-commodities-export-modal/managed-commodities-export-modal.types";
import { RejectRetirementRequestModalCustomProps } from "../reject-retirement-request-modal/reject-retirement-request-modal.types";

export type ModalHandler = {
  showContactSupportModal: () => void;
  showConfirmAcceptRejectResubmitApplicationModal: (
    args: ConfirmAcceptRejectResubmitApplicationModalCustomProps,
  ) => void;
  showIdentityMetaDataModal: (args: IdentityMetadataModalCustomProps) => void;
  showEditTradingHaltModal: (args: EditTradingHaltModalCustomProps) => void;
  showConfirmDeleteTradingHaltModal: (args: ConfirmDeleteTradingHaltModalCustomProps) => void;
  showConfirmCommodityExportModal: (args: ConfirmCommodityExportCustomProps) => void;
  showAccountRequestEditEntityModal: (args: AccountRequestEditEntityModalCustomProps) => void;
  showConfirmationModal: (args: ConfirmationModalCustomProps) => void;
  showRejectRetirementRequestModal: (args: RejectRetirementRequestModalCustomProps) => void;
  showManagedCommoditiesExportModal: (args: ManagedCommoditiesExportModalCustomProps) => void;
  showForwardTradeDetailsModal: (args: ForwardTradeDetailsCustomProps) => void;
};

export type ModalProviderProps = {
  children: React.ReactNode;
};

export enum ModalEnums {
  ContactSupportModal = "ContactSupportModal",
  ConfirmCommodityExport = "ConfirmCommodityExport",
  ConfirmAcceptRejectResubmitApplicationModal = "ConfirmAcceptRejectResubmitApplicationModal",
  IdentityMetaDataModal = "IdentityMetaDataModal",
  EditTradingHaltModal = "EditTradingHaltModal",
  ConfirmDeleteTradingHaltModal = "ConfirmDeleteTradingHaltModal",
  AccountRequestEditEntityModal = "AccountRequestEditEntityModal",
  ConfirmationModal = "ConfirmationModal",
  RejectRetirementRequestModal = "RejectRetirementRequestModal",
  ManagedCommoditiesExportModal = "ManagedCommoditiesExportModal",
  ForwardTradeDetailsModal = "ForwardTradeDetailsModal",
}

export type ModalState =
  | {
      type: ModalEnums.ContactSupportModal;
      customProps: undefined;
    }
  | {
      type: ModalEnums.ConfirmAcceptRejectResubmitApplicationModal;
      customProps: ConfirmAcceptRejectResubmitApplicationModalCustomProps;
    }
  | {
      type: ModalEnums.IdentityMetaDataModal;
      customProps: IdentityMetadataModalCustomProps;
    }
  | {
      type: ModalEnums.EditTradingHaltModal;
      customProps: EditTradingHaltModalCustomProps;
    }
  | {
      type: ModalEnums.ConfirmDeleteTradingHaltModal;
      customProps: ConfirmDeleteTradingHaltModalCustomProps;
    }
  | {
      type: ModalEnums.ConfirmCommodityExport;
      customProps: ConfirmCommodityExportCustomProps;
    }
  | {
      type: ModalEnums.AccountRequestEditEntityModal;
      customProps: AccountRequestEditEntityModalCustomProps;
    }
  | {
      type: ModalEnums.ConfirmationModal;
      customProps: ConfirmationModalCustomProps;
    }
  | {
      type: ModalEnums.RejectRetirementRequestModal;
      customProps: RejectRetirementRequestModalCustomProps;
    }
  | {
      type: ModalEnums.ManagedCommoditiesExportModal;
      customProps: ManagedCommoditiesExportModalCustomProps;
    }
  | {
      type: ModalEnums.ForwardTradeDetailsModal;
      customProps: ForwardTradeDetailsCustomProps;
    };
