import currency from "currency.js";
import { startCase } from "lodash";

import { MatchedTradeContractStatus } from "@/app/types/generated/graphql";

export const forwardTradeStatusOptions = Object.values(MatchedTradeContractStatus).map((status) => ({
  label: startCase(status.toLowerCase()),
  value: status,
}));

export const getTotalForwardTradeValue = (price: string | number, volume: number) => currency(price).multiply(volume);

export const chipVariant = {
  [MatchedTradeContractStatus.Pending]: "chips.primary",
  [MatchedTradeContractStatus.PendingComplete]: "chips.primary",
  [MatchedTradeContractStatus.Failed]: "chips.error",
  [MatchedTradeContractStatus.Accepted]: "chips.info",
  [MatchedTradeContractStatus.Completed]: "chips.info",
  [MatchedTradeContractStatus.Rejected]: "chips.error",
};
