import { useFeatureToggle } from "./use-feature-toggle";
import { FeatureToggleKey, UseAllFeatureToggleValues } from "./use-feature-toggle.types";

export const useAllFeatureToggleValues: UseAllFeatureToggleValues = () => {
  const featureToggles = Object.values(FeatureToggleKey).map((toggle) => ({
    key: toggle,
    ...useFeatureToggle({ featureKey: toggle }),
  }));

  const toggles = Object.fromEntries(featureToggles.map(({ key, value }) => [key, value])) as Record<
    FeatureToggleKey,
    any
  >;

  const loading = featureToggles.some(({ loading }) => loading);

  return { toggles, loading };
};
