import { Box, Flex, LegacySelect, Table, Text } from "@powerledger/ui-component-lib";
import { startCase } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { Input } from "@/app/components";
import { TableActionLinkButton } from "@/app/components/table-action-buttons";
import { TableActionDropdown } from "@/app/components/table-action-buttons/table-action-dropdown";
import { getSelectTranslation, getTableTranslation } from "@/app/lib/get-translations-for-components";
import { AccountType } from "@/app/types/generated/graphql";

import { ManagedCommoditiesLocationState } from "../../managed-commodities/managed-commodities.types";
import { AccountDetailsLocationState } from "../account-details/account-details.types";
import { hasPersonalRegistries } from "../account-details/use-account-details";
import { AccountsTableViewProps, AccountTableData } from "./accounts-table.types";
import { accountStatusOptions, accountTypeOptions, registryCodeOptions } from "./use-accounts-table";

const AccountTypeAbbreviations = {
  NON_REGISTRY: "NR",
  REGISTRY: "R",
};

export const AccountsTableView: React.FC<AccountsTableViewProps> = ({
  tableData,
  loading,
  onAccountsSearch,
  searchTerms,
  pageInfo,
  fetchData,
  enableMultipleRegistry,
}) => {
  const { t } = useTranslation();

  const tableColumns: Column<AccountTableData>[] = useMemo(
    () => [
      // {
      //   Header: t("Account ID"),
      //   accessor: (row) => row?.id,
      //   disableSortBy: true,
      //   Cell: ({ value }: { value: string }) => (
      //     <Flex sx={{ color: "textDarker", whiteSpace: "break-spaces", maxWidth: 350 }}>{value || "-"}</Flex>
      //   ),
      // },
      {
        Header: t("Business Name"),
        accessor: (row) => row?.company?.businessName,
        id: "businessName",
        Cell: ({ value }: { value: string }) => <Flex sx={{ color: "textDarker" }}>{value || "-"}</Flex>,
      },
      {
        Header: t("Email"),
        accessor: "email",
        id: "email",
        Cell: ({ value }: { value: string }) => (
          <Flex
            sx={{
              color: "textDarker",
            }}
          >
            {value || "-"}
          </Flex>
        ),
      },
      {
        Header: t("Account Type"),
        id: "type",
        Cell: ({ row }: { row: Row<AccountTableData> }) => {
          const { type, linkedRegistries } = row.original;

          const accountTypeInfo = enableMultipleRegistry ? type ?? linkedRegistries : type;

          if (Array.isArray(accountTypeInfo)) {
            const formattedRegistries = accountTypeInfo.map(
              ({ registryCode, accountType }) =>
                registryCode + (accountType ? ` (${AccountTypeAbbreviations[accountType]})` : ""),
            );

            return (
              <Flex
                sx={{
                  color: "textDarker",
                }}
              >
                <Text>{formattedRegistries.join(", ")}</Text>
              </Flex>
            );
          }

          return <Flex sx={{ color: "textDarker" }}>{startCase(type?.toLowerCase() ?? "-")}</Flex>;
        },
      },
      {
        Header: t("Action"),
        id: "See-Details",
        maxWidth: 100,
        Cell: ({ row }: { row: Row<AccountTableData> }) => {
          const { type, linkedRegistries } = row.original;

          const resolvedAccountType = linkedRegistries.some(
            (registry) => registry.accountType === AccountType.NonRegistry,
          )
            ? AccountType.NonRegistry
            : AccountType.Registry;

          const accountType = enableMultipleRegistry ? type ?? resolvedAccountType : type;
          return (
            <TableActionDropdown
              actions={[
                <TableActionLinkButton
                  key={row.original.id + row.index + 1}
                  linkProps={
                    { to: `/accounts/${row.original.id}`, state: { searchKeys: searchTerms } } satisfies {
                      to: string;
                      state: AccountDetailsLocationState;
                    }
                  }
                  text={t("See Details")}
                />,
                ...(!hasPersonalRegistries(accountType)
                  ? [
                      <TableActionLinkButton
                        key={row.original.id + row.index + 2}
                        linkProps={
                          {
                            to: `${row.original.id}/managed-commodities/`,
                            state: { searchKeys: searchTerms, companyName: row.original.company?.businessName },
                          } satisfies {
                            to: string;
                            state: ManagedCommoditiesLocationState;
                          }
                        }
                        text={t("See Managed Commodities")}
                      />,
                    ]
                  : []),
              ]}
            />
          );
        },
      },
    ],
    [t, searchTerms, enableMultipleRegistry],
  );
  return (
    <>
      <Flex
        sx={{
          py: 3,
          mb: 3,
          width: "100%",
          gap: 2,
          flexWrap: "wrap",
          flexDirection: ["column", "row"],
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Input
            onInput={(event) => onAccountsSearch((event.target as HTMLInputElement).value, "companyName")}
            placeholder="Enter company name to search..."
            defaultValue={searchTerms?.companyName}
            sx={{
              background: "background",
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Input
            onInput={(event) => onAccountsSearch((event.target as HTMLInputElement).value, "id")}
            placeholder="Enter account id to search..."
            defaultValue={searchTerms?.id}
            sx={{
              background: "background",
            }}
          />
        </Box>
        {enableMultipleRegistry && (
          <Box
            sx={{
              flex: 1,
            }}
            aria-label="filter registryCode type"
          >
            <LegacySelect
              sx={{
                [`div[class*="container"]>div`]: {
                  bg: "background",
                },
                [`div[class*="menu"]>div`]: {
                  bg: "background",
                },
              }}
              value={registryCodeOptions.find((option) => option.value === searchTerms.registryCode)}
              options={registryCodeOptions}
              translation={getSelectTranslation(t)}
              onChange={(option) => {
                onAccountsSearch(option?.value || "", "registryCode");
              }}
            />
          </Box>
        )}
        {!enableMultipleRegistry && (
          <Box
            sx={{
              flex: 1,
            }}
            aria-label="filter account status"
          >
            <LegacySelect
              sx={{
                [`div[class*="container"]>div`]: {
                  bg: "background",
                },
                [`div[class*="menu"]>div`]: {
                  bg: "background",
                },
              }}
              value={accountTypeOptions.find((option) => option.value === searchTerms.accountType)}
              options={accountTypeOptions}
              translation={getSelectTranslation(t)}
              onChange={(option) => {
                onAccountsSearch(option?.value ?? "", "accountType");
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            flex: 1,
          }}
          aria-label="filter account status"
        >
          <LegacySelect
            sx={{
              [`div[class*="container"]>div`]: {
                bg: "background",
              },
              [`div[class*="menu"]>div`]: {
                bg: "background",
              },
            }}
            value={accountStatusOptions.find((option) => option.value === searchTerms.status)}
            options={accountStatusOptions}
            translation={getSelectTranslation(t)}
            onChange={(option) => {
              onAccountsSearch(option?.value || "", "status");
            }}
          />
        </Box>
      </Flex>
      <Table
        columns={tableColumns}
        dataSource={tableData || []}
        pageInfo={pageInfo}
        containerSx={{
          table: {
            thead: {
              tr: {
                "th:last-of-type": {
                  width: 100,
                },
              },
            },
          },
        }}
        fetchData={fetchData}
        manualPagination
        loading={loading}
        showScrollArrows
        translation={getTableTranslation(t)}
      />
    </>
  );
};
