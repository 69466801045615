import { gql } from "@apollo/client";

export const COMMODITY_TRANSACTION_FIELDS = gql`
  fragment CommodityTransactionFields on CommodityTransaction {
    id
    volume
    status
    statusMessage
    commoditySerial {
      serialPrefix
      serialFrom
      serialTo
    }
  }
`;

export const APPROVAL_STATE_FIELDS = gql`
  fragment ApprovalStateFields on ApprovalState {
    id
    status
    errorCode
    errorMessage
  }
`;

export const DOCUMENT_FIELDS = gql`
  fragment DocumentFields on Document {
    id
    fileName
    type
    mediaType
    size
    status
    errorMessage
    signedUrl
    approvalState {
      ...ApprovalStateFields
    }
  }
`;

export const REC_ASSET_ATTRIBUTES_FRAGMENT = gql`
  fragment RecAssetAttributesFields on RecAssetAttributes {
    vintage
    vintageHalf
    location
    fuelSources
    eligibilities
    project
    certifications
    commencementDate
  }
`;

export const EXPORT_REQUEST_DETAILS_FRAGMENT = gql`
  fragment ExportRequestDetailsFields on ExportRequestDetail {
    id
    volume
    attributes {
      ...RecAssetAttributesFields
    }
    status
    statusMessage
  }
`;

export const TRADE_CONTRACT_ATTRIBUTES_FRAGMENT = gql`
  fragment RecTradeAttributesFields on RecAttributes {
    vintages
    locations
    fuelSources
    eligibilities
    projects
    certifications
    commencementDate
  }
`;

export const TRADE_CONTRACT_FRAGMENT = gql`
  fragment TradeContractDetails on TradeContract {
    id
    volume
    unitPrice
    tradeSettlementDate
    createdDate
    account {
      company {
        businessName
      }
      email
    }
    attributes {
      ...RecTradeAttributesFields
    }
  }
`;
