import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { useCallback } from "react";
import { useNavigate } from "react-router";

export const OktaSecurity: React.FC<{ oktaAuth: OktaAuth }> = ({ children, oktaAuth }) => {
  const navigate = useNavigate();

  const restoreOriginalUri = useCallback(
    async (_, originalUri: string | null) => {
      navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
        replace: true,
      });
    },
    [navigate],
  );

  const onAuthRequired = useCallback(() => {
    navigate("/", {
      replace: true,
    });
  }, [navigate]);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired}>
      {children}
    </Security>
  );
};
