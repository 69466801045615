import { PageInfo, TableFetchData } from "@powerledger/ui-component-lib";
import { DebouncedFunc } from "lodash";

import { AccountStatus, AccountType, PaginatedAccountsQuery, RegistryCode } from "@/app/types/generated/graphql";

export type SearchTerms = {
  id?: string;
  companyName?: string;
  status: AccountStatus;
  accountType: AccountType;
  registryCode: RegistryCode;
};

export type AccountTableData = NonNullable<PaginatedAccountsQuery["paginatedAccounts"]["accounts"]>["0"];

export type SearchKeys = keyof SearchTerms;

export type UseAccountsTableFn = () => {
  tableData?: AccountTableData[];
  loading: boolean;
  onAccountsSearch: DebouncedFunc<(value: string, key: SearchKeys) => void>;
  searchTerms: SearchTerms;
  fetchData: TableFetchData<AccountTableData>;
  pageInfo: PageInfo;
  enableMultipleRegistry: boolean;
};

export type AccountsTableViewProps = ReturnType<UseAccountsTableFn>;

export const TraceXRegistriesList = ["MRETS", "ERCOT"] as const;
