import { createContext, useContext, useState } from "react";

import { AppContextType, AppUserSelections } from "./app-context.types";

export const AppContext = createContext<AppContextType>({
  userSelections: null,
  setUserSelections: () => {},
});

export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userSelections, setUserSelections] = useState<AppUserSelections | null>(null);

  return (
    <AppContext.Provider
      value={{
        userSelections,
        setUserSelections,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
