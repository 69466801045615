import { LegacySelect } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { getSelectTranslation } from "@/app/lib/get-translations-for-components";

import { RegistryDropdownViewProps } from "./registry-dropdown.types";

export const RegistryDropdownView = ({
  loading,
  registries,
  activeRegistry,

  onActiveRegistryChange,
}: RegistryDropdownViewProps) => {
  const { t } = useTranslation();

  return (
    <LegacySelect
      sx={{
        width: "100%",
      }}
      isLoading={loading}
      value={activeRegistry}
      options={registries ?? []}
      translation={getSelectTranslation(t)}
      getOptionLabel={(registry) => registry.shortName}
      getOptionValue={(registry) => registry.id}
      onChange={(registry) => onActiveRegistryChange(registry ?? null)}
    />
  );
};
